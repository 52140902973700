<template>
    <div v-if="rows.length">
        <h5>{{ rows[rows.length - 1].filed_at | nibnut.date("MMM dd") }}</h5>
        <data-table
            id="crashplan-computers"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="false"
            :show-totals="false"
            :searchable="false"
            :clickable="false"
            class="non-sticky"
            @sort="sort_by"
            @page="goto_page"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6>{{ row.name }}</h6>
                <div v-if="!row.backup_progression && row.last_backup_at">
                    <strong>{{ $root.translate("Finished") }}:</strong> {{ row.last_backup_at | nibnut.date("yyyy-MM-dd HH:mm") }}
                </div>
                <div v-else-if="!!row.backup_progression">
                    <strong>{{ $root.translate("Backing up") }}:</strong> {{ (row.backup_progression / 100) | nibnut.date("yyyy-MM-dd HH:mm") }}%
                </div>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div v-if="field === 'last_backup_at'">
                    <span v-if="!row.backup_progression && row.last_backup_at">
                        <strong>{{ $root.translate("Finished") }}:</strong> {{ row.last_backup_at | nibnut.date("yyyy-MM-dd HH:mm") }}
                    </span>
                    <span v-else-if="!!row.backup_progression">
                        {{ (row.backup_progression / 100) | nibnut.number("0.00") }}%
                    </span>
                </div>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>
    </div>
</template>

<script>
import is_report from "./IsReport"

export default {
    name: "CrashplanReport",
    mixins: [is_report],
    computed: {
        state_identifier () {
            return "crashplan-report"
        }
    },
    data () {
        return {
            columns: {
                name: { label: "Computer", sort: null },
                last_backup_at: { label: "Backup status", sort: null, cell_class: "no-wrap" }
            },

            default_state: {
                per_page: 5,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}
</script>
